import { Component } from '@angular/core';

@Component({
    selector: 'ih-offline-info-header-popover',
    template: `
    <ion-card-content>
      No internet connection.<br />
      Form submissions will automatically be submitted when you are connected to
      the internet.
      <!-- TODO: -->
      Media submissions is currently unavailable.
    </ion-card-content>
  `,
    styles: [],
    standalone: false
})
export class OfflineInfoHeaderPopoverComponent {}
