import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { AuthorizationService, ToastService } from '@ih/app/client/shared/services';
import { Group, Project, User } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';
import { 
  GetMe,
  GetMeError, 
  GetMeSuccess, 
  SetMe,
  SetProjectPermissions,
  SetGroupPermissions,
  SetOrganisationPermissions,
  GetProjectPermissions,
  GetGroupPermissions,
  GetOrganisationPermissions
 } from '../actions';
import { SetPermissionsSuccess } from '../effects';

export interface MeStateModel {
  me: User | null;
  permissions : {
    site : string[],
    project: string[],
    organisation: string[],
    group: string[],
    union : string[],
  }
  errors: Error[];
}

const defaults: MeStateModel = {
  me: null,
  permissions : {
    site : [],
    project: [],
    organisation: [],
    group: [],
    union : [],
  },
  errors: [],
};

export const ME_STATE_TOKEN = new StateToken<MeStateModel>('me');

@State<MeStateModel>({
  name: ME_STATE_TOKEN, 
  defaults: defaults,
})
@Injectable()
export class MeState {
    @Selector()
    static getState(state: MeState) {
      return state;
    }


  @Selector()
  public static me(state: MeStateModel): User | null {
    return state.me;
  }

  @Selector()
  public static sitePermissions(state: MeStateModel): string[] {
    return state.permissions.site;
  }

  @Selector() 
  public static permissions(state: MeStateModel): string[] {
    return state.permissions.union;
  }

  constructor(
    private readonly toastService: ToastService,
    private readonly apollo: Apollo,
    private readonly store: Store,
    private readonly authorizationService: AuthorizationService,
  ) {}


  /**
   * @description Gets current user and sets MeStateModel.me to it
   * @updated 18/03/2024 - 09:22:11
   * @public
   * @onSuccess Dispatch {@link getMeSuccess}
   * @onFail Dispatch {@link getMeError}
   */
  @Action(GetMe)
  public getMe(ctx: StateContext<MeStateModel>) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'cache-and-network';
    }

    const query = gql`
      query {
        me {
          id
          username
          displayName
          email
          photoURL
          siteRoleId
        }
      }
    `;

    let takes = 2;
    const cachedData = this.apollo.client.cache.readQuery({
      query: query,
    });

    if (!cachedData || !networkStatus) {
      takes = 1;
    }

    return this.apollo
      .watchQuery<{ me: User }>({
        query,
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(takes),
        tap(async (result: ApolloQueryResult<{ me: User }>) =>  {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          const sitePermissions = await this.authorizationService.getSitePermissions(result.data.me.siteRoleId!);

          ctx.dispatch(new GetMeSuccess(result,sitePermissions));
        }),
        catchError((error) => ctx.dispatch(new GetMeError(error)))
      );
  }


  /**
   *  Dispatches SetMe
   *  action to set state
   * @updated 18/03/2024 - 09:22:11
   * @public
   * @dispatch {@link setMe}
   */
  @Action(GetMeSuccess)
  public async getMeSuccess(
    ctx: StateContext<MeStateModel>,
    { result,sitePermissions }: GetMeSuccess
  ) {
    const me = (result?.data?.me || null) as User;
    ctx.dispatch(new SetMe(me,sitePermissions));
  }

  /**
   * Displays a failure toast and logs it to console
   * @updated 18/03/2024 - 09:22:11
   * @public
   */
  @Action(GetMeError)
  public async getMeError(
    ctx: StateContext<MeStateModel>,
    { error }: GetMeError
  ) {
    this.toastService.showError('Failed to fetch current user');
    console.error('Failed to fetch current user: ', error.message);
  }

  /**
   * Sets MeStateModel.me to the provided user
   * @updated 18/03/2024 - 09:22:11
   * @public
   */
  @Action(SetMe)
  public setMe(ctx: StateContext<MeStateModel>, { me,sitePermissions }: SetMe) {
    ctx.setState(
      produce((draft) => {
        draft.me = me;
        draft.permissions = {
          site : sitePermissions,
          project: [],
          organisation: [],
          group: [],
          union : [],
        }
      })
    );

  }

  @Action(GetProjectPermissions)
  public async getProjectPermissions(ctx: StateContext<MeStateModel>, { projectId, userId }: GetProjectPermissions) {
    const projectPermissions = await this.authorizationService.getProjectPermissions(projectId, userId);

    ctx.dispatch(new SetProjectPermissions(projectPermissions));
  }

  @Action(SetProjectPermissions)
  public setProjectPermissions(ctx: StateContext<MeStateModel>, { projectPermissions }: SetProjectPermissions) {
    ctx.setState(
      produce((draft) => {
        draft.permissions.project = projectPermissions;
        draft.permissions.group = [];
        draft.permissions.organisation = [];
      })
    );

    const project = this.store.selectSnapshot<Project>(
      (state) => state.project.project
    );


    if(project && project.groupLabel){ // project inside group of organisation
      ctx.dispatch(new GetGroupPermissions(ctx.getState().me!.id,project.groupLabel.id));
    }
    else
    if(project && project.organisationLabel){  // Project owned by organisation
      ctx.dispatch(new GetOrganisationPermissions(ctx.getState().me!.id,project.organisationLabel.id));
    }
    else
    ctx.dispatch(new SetPermissionsSuccess()); // Project is standalones
  }

  @Action(GetGroupPermissions)
  public async getGroupPermissions(ctx: StateContext<MeStateModel>, { groupId, userId,meta }: GetGroupPermissions) {
    const groupPermissions = await this.authorizationService.getGroupPermissions(groupId, userId);
    ctx.dispatch(new SetGroupPermissions(groupPermissions,meta));
  }

  @Action(SetGroupPermissions)
  public setGroupPermissions(ctx: StateContext<MeStateModel>, { groupPermissions,meta }: SetGroupPermissions) {
    ctx.setState(
      produce((draft) => {
        draft.permissions.group = groupPermissions;
        draft.permissions.organisation = [];
        draft.permissions.project = meta ? [] : draft.permissions.project;
      })
    );

    const group = this.store.selectSnapshot<Group>(
      (state) => state.group.group
    );

    if(group && group.organisationLabel){
      ctx.dispatch(new GetOrganisationPermissions(ctx.getState().me!.id,group.organisationLabel.id));
    }
    else
    ctx.dispatch(new SetPermissionsSuccess());

  }



  @Action(GetOrganisationPermissions)
  public async getOrganisationPermissions(ctx: StateContext<MeStateModel>, { organisationId, userId,meta }: GetOrganisationPermissions) {
    const organisationPermissions = await this.authorizationService.getOrganisationPermissions(organisationId, userId);

    ctx.dispatch(new SetOrganisationPermissions(organisationPermissions,meta));
  }

  @Action(SetOrganisationPermissions)
  public setOrganisationPermissions(ctx: StateContext<MeStateModel>, { organisationPermissions,meta }: SetOrganisationPermissions) {
    ctx.setState(
      produce((draft) => {
        draft.permissions.organisation = organisationPermissions;
        draft.permissions.group = meta ? [] : draft.permissions.group;
        draft.permissions.project = meta ? [] : draft.permissions.project;
      })
    );

    ctx.dispatch(new SetPermissionsSuccess());
  }

  @Action(SetPermissionsSuccess)
  public setPermissionsSuccess(ctx: StateContext<MeStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.permissions.union = [
          ...new Set([
            ...draft.permissions.site,
            ...draft.permissions.project,
            ...draft.permissions.group,
            ...draft.permissions.organisation
          ])
        ];
        
      })
    );
    console.info('permissions',ctx.getState().permissions);
  }

  
}
