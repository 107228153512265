import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IStepOption } from 'ngx-ui-tour-ng-bootstrap';

@Injectable({
  providedIn: 'root',
})

// TODO : Move steps out of memory, additionally de-register triggers dynamically to reduce memory usage
export class TourStepService {
  private allSteps: { [route: string]: IStepOption[] } = {
    '/home/create': [
      {
        anchorId: 'feature.projects.create.owner.tour',
        content:
          'You can select the owner of a project. It can either be you, your organisation or a group you belong to.',
        title: 'Owner',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'feature.projects.create.name.tour',
        content: 'You can choose the name of your project',
        title: 'Project Name',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'feature.projects.create.group.tour',
        content: 'You can select a group as the owner of the project',
        title: 'Group',
        enableBackdrop: true,
        isOptional: true,
      },
      {
        anchorId: 'feature.projects.create.visibility.tour',
        content:
          'This determines who can view your project. Private will be just you, Protected (stub), and Public will be everyone',
        title: 'Project Visibility',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'feature.projects.create.description.tour',
        content: 'This is a short description that you can add to your project.',
        title: 'Project Description',
        isAsync: true,
        enableBackdrop: true,
      },
    ],
    '/home': [
      {
        anchorId: 'first.project.tour',
        content: 'This is your first project. Click here to view it.',
        title: 'First Project',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'create.project.tour',
        content: 'Click here to create a new project.',
        title: 'Create Project',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'browse.project.tour',
        content: 'Click here to browse other organisations projects.',
        title: 'Browse Projects',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'search.project.tour',
        content: 'Click here to Search for projects.',
        title: 'Search Projects',
        isAsync: true,
        enableBackdrop: true,
      },
    ],
    '/home/settings': [
      {
        anchorId: 'home.settings.general',
        content: 'Update your basic user details here.',
        title: 'User Details',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'home.settings.security',
        content: `You can change your account's password here`,
        title: 'Account Security',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'home.settings.apikeys',
        content: 'API Keys are used to authenticate with the API and are required for accessing the API from your own applications or devices.',
        title: 'API Keys',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'home.settings.offline',
        content: 'If you are worried about connection stability, you can manually toggle the app between online and offline mode.',
        title: 'Toggle Offline',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'home.settings.cache',
        content: 'Any pending offline media uploads and form submissions will be lost when clearing the cache.',
        title: 'Clear Cache',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'home.settings.history',
        content: 'All jobs and their corresponding history, data and information will be permanently removed from this device',
        title: 'Job History',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'home.settings.update',
        content: 'If you believe your app is out of date, you can manually check for updates here.',
        title: 'Update App',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'home.settings.account',
        content: 'You will be logged out of the app and all data will be removed from this device.',
        title: 'Delete account',
        isAsync: true,
        enableBackdrop: true,
      }

    ],
    '/home/organisations/create': [
      {
        anchorId: 'create.organisation.name',
        content: 'Indicate the name of the new organisation to be created',
        title: 'Name',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'create.organisation.visibility',
        content: 'Specify whether the organisation should be visible to everyone or just its members',
        title: 'Visibility',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'create.organisation.description',
        content: 'You can specify any extra details about the organisation here',
        title: 'Description',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'create.organisation.create',
        content: 'Click here to create the organisation',
        title: 'Create',
        isAsync: true,
        enableBackdrop: true,
      }
    ],
    '/menu': [
      {
        anchorId: 'hello2',
        content: 'Click here to create a new project.',
        title: 'Create Project',
        enableBackdrop: true,
        isOptional: true,
      },
    ],
    '/project/copy': [
      {
        anchorId: 'copy.project.owner',
        content: 'Specify who will own the copied project',
        title: 'Owner of project',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'copy.project.name',
        content: 'Specify the name of the copied project',
        title: 'Name of new project',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'copy.project.structures',
        content: 'Specify what components of the project you want to copy over.',
        title: 'Copy Structures',
        isAsync: true,
        enableBackdrop: true,
      },
      {
        anchorId: 'copy.project.data',
        content: 'From the selected components, specify if the components data (records,files,etc) should be copied over as well.',
        title: 'Copy Data',
        isAsync: true,
        enableBackdrop: true,
      }      
    ],
    '/project/users/invite': [
      {
        anchorId: 'invite.project.users',
        content: 'Search for the user by entering their details. If the user is not found, you can specify their email address to add them.',
        title: 'Invite Users',
        isAsync: true,
        enableBackdrop: true
      }
    ],
    '/group/settings': [
      {
        anchorId: 'group.settings.general',
        content: 'The group settings page allows you to modify the general details of the group, specifically the name, description and visibility.',
        title: 'Settings',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.settings.logo',
        content: 'You can change the logo (Image displayed for group) here by uploading a new image.',
        title: 'Logo',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.settings.name',
        content: 'Change the name of the current group here',
        title: 'Name',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.settings.visibility',
        content: 'Specify whether the group should be visible to everyone or just its members (group and organisation members)',
        title: 'visibility',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.settings.description',
        content: 'Change the description of the current group here',
        title: 'Description',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.settings.save',
        content: 'Once you are satisfied with the changes, click here to save the changes',
        title: 'Save',
        isAsync: true,
        enableBackdrop: true
      },
    ],
    '/group/users/invite': [
      {
        anchorId: 'group.users.invite',
        content: 'Search for the user by entering their details. If the user is not found, you can specify their email address to add them.',
        title: 'Invite Users',
        isAsync: true,
        enableBackdrop: true
      }
    ],
    '/group/project/create': [
      {
        anchorId: 'group.project.create.general',
        content: 'Create a new project for your group to manage tables, forms, dashboards and many more.',
        title: 'Create Project',
        isAsync: true,
        enableBackdrop: true
      },
      // {
      //   anchorId: 'group.project.create.owner',
      //   content: 'Indicate the owner of the project. It can be you, your organisation or a group you belong to.',
      //   title: 'Invite Users',
      //   isAsync: true,
      //   enableBackdrop: true
      // },
      {
        anchorId: 'group.project.create.name',
        content: 'Specify the name of the new project to be created. The project must have a unique name inside the group.',
        title: 'Name',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.project.create.visibility',
        content: 'Indicate whether the project should be visible only to members of the project and the group, or to anyone on the Information Hub.',
        title: 'Visibility',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.project.create.description',
        content: 'Optionally you can add a short description to the project to indicate any extra details about the project or to summarise its purpose.',
        title: 'Description',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'group.project.create.create',
        content: 'Once you are done, click here to create the project.',
        title: 'Create',
        isAsync: true,
        enableBackdrop: true
      },
    ],
    '/organisation/project/create': [
      {
        anchorId: 'organisation.project.create.general',
        content: 'Create a new project for your organisation to manage tables, forms, dashboards and many more.',
        title: 'Create Project',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.project.create.owner',
        content: 'Indicate whether the project must be owned by the organisation or a group within the organisation.',
        title: 'Ownership',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.project.create.name',
        content: 'Specify the name of the new project to be created. The project must have a unique name inside the organisation.',
        title: 'Name',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.project.create.visibility',
        content: 'Indicate whether the project should be visible only to members of the project and the organisation, or to anyone on the Information Hub.',
        title: 'Visibility',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.project.create.description',
        content: 'Optionally you can add a short description to the project to indicate any extra details about the project or to summarise its purpose.',
        title: 'Description',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.project.create.submit',
        content: 'Once you are done, click here to create the project.',
        title: 'Create',
        isAsync: true,
        enableBackdrop: true
      },
    ],
    '/organisation/settings': [
      {
        anchorId: 'organisation.settings.general',
        content: 'The organisation settings page allows you to modify the general details of the organisation, specifically the name, description and visibility.',
        title: 'Settings',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.settings.logo',
        content: 'You can change the logo (Image displayed for organisation) here by uploading a new image.',
        title: 'Logo',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.settings.name',
        content: 'Change the name of the current organisation here',
        title: 'Name',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.settings.visibility',
        content: 'Specify whether the organisation should be visible to everyone or just its members',
        title: 'visibility',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.settings.description',
        content: 'Change the description of the current organisation here',
        title: 'Description',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.settings.save',
        content: 'Once you are satisfied with the changes, click here to save the changes',
        title: 'Save',
        isAsync: true,
        enableBackdrop: true
      },
    ],
    '/organisation/users/invite': [
      {
        anchorId: 'organisation.users.invite',
        content: 'Search for the user by entering their details. If the user is not found, you can specify their email address to add them.',
        title: 'Invite Users',
        isAsync: true,
        enableBackdrop: true
      }
    ],
    '/organisation/group/create': [
      {
        anchorId: 'organisation.group.create.general',
        content: 'Create a new group to manage your team and projects within your organisation.',
        title: 'Create Group',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.group.create.name',
        content: 'Specify the name of the new group to be created. The group must have a unique name inside the organisation.',
        title: 'Name',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.group.create.description',
        content: 'Optionally you can add a short description to the group to indicate any extra details about the group or to summarise its purpose.',
        title: 'Description',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.group.create.visibility',
        content: 'Indicate whether the group should be visible only to members of the group and the group, or to anyone on the Information Hub.',
        title: 'Visibility',
        isAsync: true,
        enableBackdrop: true
      },
      {
        anchorId: 'organisation.group.create.submit',
        content: 'Once you are done, click here to create the group.',
        title: 'Create',
        isAsync: true,
        enableBackdrop: true
      },
    ]
  };

  private stepsSubject = new BehaviorSubject<IStepOption[]>([]);
  private tourRestartSubject = new Subject<void>();
  private componentTriggers: Map<string, Subject<void>> = new Map();

  steps$ = this.stepsSubject.asObservable();
  tourRestart$ = this.tourRestartSubject.asObservable();

  getSteps(route: string): IStepOption[] {
    return this.allSteps[route] || [];
  }

  resetSteps(route?: string) {
    if (route) {
      this.allSteps[route] = [];
    } else {
      Object.keys(this.allSteps).forEach((key) => {
        this.allSteps[key] = [];
      });
    }
    this.stepsSubject.next([]);
  }

  isRegistered(route: string): boolean {
    return this.componentTriggers.has(route);
  }

  requestTourRestart() {
    this.tourRestartSubject.next();
  }

  registerTrigger(route: string): Subject<void> {
    if (!this.componentTriggers.has(route)) {
      this.componentTriggers.set(route,new Subject<void>());
    }

    return this.componentTriggers.get(route)!;
  }


  triggerAll(route: string) {
    const trigger = this.componentTriggers.get(route);
    if (trigger) {
      trigger.next();
    }
  }
}
