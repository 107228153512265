import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchPolicy } from '@apollo/client/core';
import {
  CreateBoardRequest,
  DeleteBoardRequest,
  FindAllBoardsRequest,
  FindUniqueBoardRequest,
  Board,
  UpdateBoardRequest,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable()
export class ManagersService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  findUniqueBoard(request: FindUniqueBoardRequest): Observable<Board | null> {
    type ResultType = { findUniqueBoard: Board | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindUniqueBoard($request: FindUniqueBoardRequest!) {
        findUniqueBoard(request: $request) {
          id
          name
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <FetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<ResultType>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          return result.data.findUniqueBoard;
        })
      );
  }

  findAllBoards(request: FindAllBoardsRequest): Observable<Board[]> {
    type ResultType = { findAllBoards: Board[] };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllBoards($request: FindAllBoardsRequest!) {
        findAllBoards(request: $request) {
          id
          name
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <FetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<ResultType>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          return result.data.findAllBoards;
        })
      );
  }

  createBoard(request: CreateBoardRequest): Observable<Board | null> {
    type ResultType = { createBoard: Board | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation CreateBoard($request: CreateBoardRequest!) {
            createBoard(request: $request) {
              id
              name
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map(
          (result: any) =>
            result.data?.createBoard || null
        )
      );
  }

  deleteBoard(request: DeleteBoardRequest): Observable<Board | null> {
    type ResultType = { deleteBoard: Board | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeleteBoard($request: DeleteBoardRequest!) {
            deleteBoard(request: $request) {
              id
              name
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map(
          (result: any) =>
            result.data?.deleteBoard || null
        )
      );
  }

  updateBoard(request: UpdateBoardRequest): Observable<Board | null> {
    type ResultType = { updateBoard: Board | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation UpdateBoard($request: UpdateBoardRequest!) {
            updateBoard(request: $request) {
              id
              name
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map(
          (result: any) =>
            result.data?.updateBoard || null
        )
      );
  }
}
